import React, { useEffect, useRef, useState } from "react";
import {
    Autocomplete,
    Box,
    Button,
    capitalize,
    Checkbox,
    CircularProgress,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useSnackbar } from "notistack";
import { SchemaRes } from "../../../../components.js/SchemaReserva";
import { checkinAdmin, getPaisos, searchPersona } from "../../../../database/API";
import { ArrowDown } from "react-feather";
import { relacio } from "../../../../utils/Utils";
window.Pusher = require("pusher-js");

const CheckinAdmin = ({ reserva, open, setOpen, setUp, municipis, provincies, paisos }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [values_persones, setValuesPersones] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const { schemaCheckinAdmin } = SchemaRes();
    const personesRefs = useRef([]);
    const [persones, setPersones] = useState([]);

    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        clearErrors,
        control,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schemaCheckinAdmin),
    });

    useEffect(() => {
        unregister("persones");
        clearErrors();
        let vals = [];
        for (var i = 0; i < reserva?.persones_val; i++) {
            vals.push({
                nom: "",
                cognom1: "",
                cognom2: "",
                accepta: false,
                genere: "M",
                nacionalitat: "",
                naixement: "",
                tipus: "D",
                dni: "",
                caducitat: "",
                codi_postal: "",
                municipi: "",
                provincia: "",
                suport: "",
                relacio: "",
                menor: false,
            });
        }
        setValuesPersones(vals);
    }, [reserva?.persones_val]);

    const enviar = async (values) => {
        setLoading(true);
        const { message } = await checkinAdmin(values, reserva?.unique_id);
        enqueueSnackbar(message, {
            variant: "success",
        });
        setLoading(false);
        setOpen(false);
        setUp((prev) => prev + 1);
    };

    const onInputChange = async (event, value, reason) => {
        if (value.length > 3) {
            const result = await searchPersona(value);
            console.log(result.persones);
            setPersones(result.persones);
        } else {
            setPersones([]);
        }
    };

    const copiarDades = (index) => {
        setValue(`persones[${index}]`, search);
        setValue(`persones.${index}.genere`, search.genere);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="xl">
            <DialogTitle>
                <Typography variant="h2">Omple les dades</Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(enviar)}>
                    <Box>
                        {values_persones.map((value, index) => {
                            const fieldName = `persones[${index}]`;
                            return (
                                <>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} my={6}>
                                        <Typography variant="h3">
                                            {"Persona"} {index + 1}
                                        </Typography>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={persones}
                                                sx={{ width: 300 }}
                                                getOptionLabel={(option) => option.nom + " " + option.cognom1 + " | " + option.dni + " | " + option.id}
                                                onInputChange={onInputChange}
                                                onSelect={(e) => console.log(e)}
                                                onChange={(event, newValue) => {
                                                    setSearch(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Buscar Persona" />}
                                            />
                                            <Tooltip title={"Copiar dades a la persona " + (index + 1)}>
                                                <IconButton onClick={() => copiarDades(index)}>
                                                    <ArrowDown />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Grid container spacing={3}>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                error={Boolean(errors && errors?.persones?.[index]?.nom)}
                                                helperText={errors && errors?.persones?.[index]?.nom?.message}
                                                key={index}
                                                fullWidth
                                                label={"Nom"}
                                                name={`${fieldName}.nom`}
                                                type="text"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                {...register(`persones.${index}.nom`)}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                error={Boolean(errors && errors?.persones?.[index]?.cognom1)}
                                                helperText={errors && errors?.persones?.[index]?.cognom1?.message}
                                                fullWidth
                                                label={"Primer cognom"}
                                                type="text"
                                                variant="outlined"
                                                name={`${fieldName}.cognom1`}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                {...register(`persones.${index}.cognom1`)}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                error={Boolean(errors && errors?.persones?.[index]?.cognom2)}
                                                helperText={errors && errors?.persones?.[index]?.cognom2?.message}
                                                fullWidth
                                                label={"Segon cognom"}
                                                type="text"
                                                variant="outlined"
                                                name={`${fieldName}.cognom2`}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                {...register(`persones.${index}.cognom2`)}
                                            />
                                        </Grid>
                                    </Grid>

                                    {index === 0 ? (
                                        <input type="checkbox" {...register(`persones.${index}.menor`)} value={false} hidden />
                                    ) : (
                                        <FormControlLabel
                                            control={<Checkbox onChange={(e) => {}} name={`${fieldName}.menor`} {...register(`${fieldName}.menor`)} />}
                                            style={{
                                                paddingTop: 20,
                                            }}
                                            label={
                                                <Box lineHeight={"9px"}>
                                                    <Typography>Menor de 14 anys</Typography>
                                                    <Typography variant="caption">(Sense document d'identificació)</Typography>
                                                </Box>
                                            }
                                        />
                                    )}
                                    <Grid spacing={3} container>
                                        <Grid item md={6} xs={12} mt={2}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="relacio">Parentesc amb la persona 1 (Contacte)</InputLabel>
                                                <Select native label={"Parentesc amb la persona 1 (Contacte)"} {...register(`${fieldName}.relacio`)}>
                                                    <option value={""}></option>
                                                    {relacio?.map((rel) => (
                                                        <option value={rel.id}>{rel.nom}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                error={Boolean(errors && errors?.persones?.[index]?.naixement)}
                                                helperText={errors && errors?.persones?.[index]?.naixement?.message}
                                                fullWidth
                                                label={"Data naixement"}
                                                margin="normal"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name={`${fieldName}.naixement`}
                                                {...register(`${fieldName}.naixement`)}
                                            />
                                        </Grid>
                                    </Grid>

                                    {!watch(`${fieldName}.menor`) && (
                                        <Box ref={personesRefs.current[index]}>
                                            <Grid container spacing={3}>
                                                <Grid item md={4} mt={1}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor={`${fieldName}.nacionalitat`}>País</InputLabel>
                                                        <Select native label={"País"} {...register(`${fieldName}.nacionalitat`)}>
                                                            <option value={""}></option>
                                                            {paisos?.map((pais) => (
                                                                <option value={pais?.id}>{pais?.nom}</option>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={4} mt={1}>
                                                    <Collapse in={watch(`${fieldName}.nacionalitat`) === "ESP"}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="tipus">{"Provincia"}</InputLabel>
                                                            <Select native label={"Provincia"} {...register(`${fieldName}.provincia`)}>
                                                                <option value={""}></option>
                                                                {provincies?.map((provincia) => (
                                                                    <option value={provincia?.id}>{provincia?.nom}</option>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Collapse>
                                                </Grid>

                                                <Grid item md={4} mt={1}>
                                                    <Collapse in={watch(`${fieldName}.nacionalitat`) === "ESP"}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="tipus">{"Municipi"}</InputLabel>
                                                            <Select native label={"Municipi"} {...register(`${fieldName}.municipi`)}>
                                                                <option value={""}></option>
                                                                {municipis
                                                                    ?.filter((m) => m.provincia_id === watch(`${fieldName}.provincia`))
                                                                    ?.map((municipi) => (
                                                                        <option value={municipi?.id}>{municipi?.nom}</option>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Collapse>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} mt={2}>
                                                <Grid item md={4} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.codi_postal)}
                                                        helperText={errors && errors?.codi_postal?.message}
                                                        fullWidth
                                                        label={"Còdi postal"}
                                                        type="text"
                                                        variant="outlined"
                                                        name={`${fieldName}.codi_postal`}
                                                        {...register(`${fieldName}.codi_postal`)}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.poblacio)}
                                                        helperText={errors && errors?.poblacio?.message}
                                                        fullWidth
                                                        label={"Població"}
                                                        type="text"
                                                        variant="outlined"
                                                        name={`${fieldName}.poblacio`}
                                                        {...register(`${fieldName}.poblacio`)}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.direccio)}
                                                        helperText={errors && errors?.direccio?.message}
                                                        fullWidth
                                                        label="Direcció"
                                                        type="direccio"
                                                        variant="outlined"
                                                        name={`${fieldName}.direccio`}
                                                        {...register(`${fieldName}.direccio`)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box my={4} />
                                            <Box mt={3} mb={2}>
                                                <Typography variant="h3">{"Dades del document d'identitat"}</Typography>
                                            </Box>
                                            <Grid container spacing={3}>
                                                <Grid item md={3} xs={12}>
                                                    <Box mt={2}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="tipus">{"Tipus de document"}</InputLabel>
                                                            <Select
                                                                native
                                                                label={"Tipus de document"}
                                                                name={`${fieldName}.tipus`}
                                                                {...register(`${fieldName}.tipus`)}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            >
                                                                <option value={"D"}>DNI</option>
                                                                <option value={"P"}>Passaport</option>
                                                                <option value={"C"}>Carnet de conduir</option>
                                                                <option value={"I"}>Carta d'identitat</option>
                                                                <option value={"N"}>Permís de residència</option>
                                                                <option value={"X"}>Permís de residència fora UE</option>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.persones?.[index]?.dni)}
                                                        helperText={errors && errors?.persones?.[index]?.dni?.message}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        label={"Número de document"}
                                                        margin="normal"
                                                        variant="outlined"
                                                        name={`${fieldName}.dni`}
                                                        {...register(`${fieldName}.dni`)}
                                                    />
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.persones?.[index]?.caducitat)}
                                                        helperText={errors && errors?.persones?.[index]?.caducitat?.message}
                                                        fullWidth
                                                        label={"Data d'expedició"}
                                                        margin="normal"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            inputProps: {
                                                                max: moment().format("YYYY-MM-DD"),
                                                            },
                                                        }}
                                                        name={`${fieldName}.caducitat`}
                                                        {...register(`${fieldName}.caducitat`)}
                                                    />
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Box mt={2}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="genere">{"Gènere"}</InputLabel>
                                                            <Select native label={"Gènere"} name={`${fieldName}.genere`} {...register(`${fieldName}.genere`)}>
                                                                <option value={"M"}>M</option>
                                                                <option value={"F"}>F</option>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box my={2} />
                                            <Grid container spacing={3}>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.suport)}
                                                        helperText={errors && errors?.suport?.message}
                                                        fullWidth
                                                        label={"Número de suport del DNI"}
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        name={`${fieldName}.suport`}
                                                        {...register(`${fieldName}.suport`)}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.email)}
                                                        helperText={errors && errors?.email?.message}
                                                        fullWidth
                                                        label="E-mail"
                                                        margin="normal"
                                                        type="email"
                                                        variant="outlined"
                                                        name={`${fieldName}.email`}
                                                        {...register(`${fieldName}.email`)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}

                                    <Box my={4}>
                                        <Divider />
                                    </Box>
                                </>
                            );
                        })}
                        <Box py={2}>
                            <Button type="submit" variant="contained" fullWidth>
                                {loading ? <CircularProgress size={20} /> : "Completar check-in"}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CheckinAdmin;
