import { useTranslation } from "react-i18next";
import * as yup from "yup";

const SchemaRes = () => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        persones: yup.array().of(
            yup.object().shape({
                nom: yup.string().required(t("Nom obligatori")),
                cognom1: yup.string().required(t("Cognom obligatori")),
                dni: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Número obligatòri")),
                }),
                suport: yup.string().when("menor", {
                    is: false,
                    then: yup
                        .string()
                        .required(t("Número de suport obligatòri"))
                        .min(9, t("El número ha de ser de 9 xifres exactes"))
                        .max(9, t("El número ha de ser de 9 xifres exactes")),
                }),

                nacionalitat: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("País obligatòri")),
                }),
                naixement: yup.string().required(t("Data de naixement obligatòria")),

                caducitat: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Data de d'expedició del document obligatòria")),
                }),
                // dniDarrere: yup.mixed().when("menor", {
                //     is: false,
                //     then: yup.mixed().test("required", t("La fotografia del document és obligatòria"), (file) => {
                //         return file[0] && file[0].size;
                //     }),
                // }),
                // dniDavant: yup.mixed().when("menor", {
                //     is: false,
                //     then: yup.mixed().test("required", t("La fotografia del document és obligatòria"), (file) => {
                //         return file[0] && file[0].size;
                //     }),
                // }),
                provincia: yup.string().when("menor", {
                    is: false,
                    then: yup.string().when("nacionalitat", {
                        is: "ESP",
                        then: yup.string().required(t("Província obligatoria")),
                    }),
                }),

                municipi: yup.string().when("menor", {
                    is: false,
                    then: yup.string().when("nacionalitat", {
                        is: "ESP",
                        then: yup.string().required(t("Municipi obligatòri")),
                    }),
                }),

                codi_postal: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Còdi postal obligatòri")),
                }),

                direccio: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Adreça obligatoria")),
                }),

                poblacio: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Població obligatoria")),
                }),

                relacio: yup.string().when("menor", {
                    is: true,
                    then: yup.string().required(t("Relació obligatoria")),
                }),
            })
        ),
    });

    const schemaCheckinAdmin = yup.object().shape({
        persones: yup.array().of(
            yup.object().shape({
                nom: yup.string().required(t("Nom obligatori")),
                cognom1: yup.string().required(t("Cognom obligatori")),
                cognom2: yup.string().required(t("Cognom obligatori")),
                dni: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Número obligatòri")),
                }),

                nacionalitat: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Nacionalitat obligatoria")),
                }),
                naixement: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Data de naixement obligatòria")),
                }),

                caducitat: yup.string().when("menor", {
                    is: false,
                    then: yup.string().required(t("Data de d'expedició del document obligatòria")),
                }),
            })
        ),
    });

    const schemaXec = yup.object({
        nom: yup.string().required(t("Nom obligatori")),
        missatge: yup.string(),
        email: yup.string().email(t("Ha de ser un E-mail vàlid")).max(255).required(t("E-mail obligatori")),
        phone: yup.string().required(t("Telèfon obligatori")),
        nom_regal: yup.string().max(35, t("Màxim 35 caràcters")).required(t("El/s nom/s a qui va dirigit el regal és obligatori")),
    });

    return { schema, schemaXec, schemaCheckinAdmin };
};

const schemaAdmin = yup.object().shape({
    persones: yup.array().of(
        yup.object().shape({
            nom: yup.string().required("Nom obligatori"),
            cognom1: yup.string().required("Cognom obligatori"),
            cognom2: yup.string().required("Cognom obligatori"),
        })
    ),
});

export { SchemaRes, schemaAdmin };
