import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { datesBetween, datesBetween2, datesBetweenInclosos, mergeIntervals } from "../../../../utils/Utils";
import moment from "moment";
import { useSnackbar } from "notistack";
import { calendaris, calendarisBooking, get, updateElement } from "../../../../database/API";
import { ca } from "date-fns/locale";

const vacances = "cbbdq5e8mk85n9a5vi0d706lag@group.calendar.google.com";

export const DialogEditarData = ({ open, setOpen, reserva }) => {
    const [dies, setDies] = useState([]);
    const [intervals, setIntervals] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [exclude, setExclude] = useState([]);
    const [dateS, setDateS] = useState(new Date(reserva?.dateS));
    const [dateF, setDateF] = useState(new Date(reserva?.dateF));
    const [preu, setPreu] = useState(0);
    const [preus, setPreus] = useState();
    const [loading, setLoading] = useState(false);
    const habitacio = reserva?.habitacio;

    useEffect(() => {
        if (open) {
            setDateF(new Date(reserva?.dateF));
            setDateS(new Date(reserva?.dateS));
            setPreu(reserva?.preu);
        } else {
            setDateF("");
            setDateS("");
            setPreu("");
        }
    }, [open]);

    useEffect(() => {
        setLoading(true);
        const obtenir = async () => {
            const preus = await get("preus");
            const dies = await get("dies");
            console.log(preus);
            console.log(reserva);

            setPreus(preus.resultat);
            setDies(dies.resultat);
            setLoading(false);
        };
        open && obtenir();
    }, [open]);

    useEffect(() => {
        setLoading(true);
        const getCalendars = async () => {
            const result = await calendaris(habitacio?.calendari);

            const result_booking = await calendarisBooking(habitacio?.id);

            const result_vacances = await calendaris(vacances);

            const result_int = result.map((item) => {
                return { start: item?.start?.date, end: item?.end?.date };
            });

            const result_booking_int = result_booking.map((item) => {
                return {
                    start: moment(item.startDate, "YYYYMMDD").format("YYYY-MM-DD"),
                    end: moment(item.endDate, "YYYYMMDD").add(1, "days").format("YYYY-MM-DD"),
                };
            });

            const result_vacances_int = result_vacances.map((item) => {
                return { start: item?.start?.date, end: item?.end?.date };
            });

            const resultats = [...result_int, ...result_booking_int, ...result_vacances_int];

            if (resultats.length > 0) {
                const interval = resultats.map((i) => [moment(i.start).add(1, "day").format("YYYY-MM-DD"), i.end]);

                const result = mergeIntervals(interval);
                setIntervals(result);

                let ex = [];
                result.forEach((item) => {
                    if (item[0]) {
                        ex = [...ex, ...datesBetween2(item[0], item[1])];
                    }
                });

                setExclude(ex);
            }

            setTimeout(() => {
                setLoading(false);
            }, 300);
        };

        open && getCalendars();
    }, [habitacio]);

    const onChange = (dates) => {
        const [start, end] = dates;
        const datesOcupades = datesBetweenInclosos(start, end);
        const diaTrobat = dies?.find((dia) => moment(dia.dia).format("YYYY-DD-MM") === moment(start).format("YYYY-DD-MM"));

        let trobat = [];
        let trobat_bool = false;
        let data1;
        let data2;
        intervals.forEach((data) => {
            const datesInterval = datesBetweenInclosos(
                moment(data[0]).subtract(1, "day").toDate().getTime(),
                moment(data[1]).subtract(1, "day").toDate().getTime()
            );
            let i = 0;
            datesInterval.forEach((dataInt, index) => {
                datesOcupades.forEach((item, index) => {
                    if (item.getTime() === dataInt.getTime()) {
                        i = i + 1;
                    }
                    if (i === 2) {
                        trobat_bool = true;
                        data1 = moment(data[0]).subtract(1, "day");
                        data2 = moment(data[1]).subtract(1, "day");
                    }
                });
            });
        });
        if (trobat_bool) {
            enqueueSnackbar("Hi ha una reserva entre les dates seleccionades: " + data1.format("YYYY-MM-DD") + " i " + data2.format("YYYY-MM-DD"), {
                variant: "warning",
            });
            trobat = [...trobat, data1];
        }
        datesOcupades.forEach((item) => {
            trobat = [...trobat, ...exclude.filter((d) => d.getTime() === item.getTime())];
        });

        const datesOcupadesNoInclude = datesBetween(start, end);

        // const boolDosNits = showNits ? datesOcupadesNoInclude.length === 0 || datesOcupadesNoInclude.length >= 2 : true;
        const boolDosNits = Number(diaTrobat?.dosnits) ? datesOcupadesNoInclude.length === 0 || datesOcupadesNoInclude.length >= 2 : true;

        let preu = preus?.filter((i) => Number(i?.persones) === Number(reserva?.persones_val) && Number(i?.habitacio_id) === Number(habitacio?.id))?.[0]?.preu;
        console.log(preu);
        if (diaTrobat) {
            preu = Math.round(preu * (diaTrobat.tantpercent_queralt ? diaTrobat.tantpercent_queralt / 100 + 1 : 1) * 100) / 100;
        }

        if (trobat.length === 0 && start?.getTime() !== end?.getTime() && boolDosNits) {
            setDateS(start);
            setDateF(end);
            setPreu(datesOcupadesNoInclude.length === 0 ? preu : Math.round(datesOcupadesNoInclude.length * preu * 100) / 100);
        } else {
            setDateS();
            setDateF();
            setPreu(preu);
            if (Number(diaTrobat?.dosnits) && moment(start).day() !== 1) {
                enqueueSnackbar("A aquestes dates, són mínim dos nits", {
                    variant: "warning",
                });
            }
        }
    };

    const guardar = async () => {
        setLoading(true);
        if (dateS && dateF) {
            const data = {
                id: reserva?.id,
                dateS: moment(dateS).format("YYYY-MM-DD HH:mm:ss"),
                dateF: moment(dateF).format("YYYY-MM-DD HH:mm:ss"),
                preu: preu,
            };
            console.log(data);
            const resultat = await updateElement("reservaDates", reserva?.id, data);
            console.log(resultat);
            if (resultat.error) {
                enqueueSnackbar("Error al modificar la reserva", {
                    variant: "error",
                });
            } else {
                enqueueSnackbar("Reserva modificada correctament", {
                    variant: "success",
                });
                setOpen(false);
            }
        } else {
            enqueueSnackbar("Tots els camps són obligatoris", {
                variant: "warning",
            });
        }
        setLoading(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={"lg"}>
            <DialogContent>
                <Box textAlign={"center"}>
                    <Typography variant="h2">{"Modificar data reserva"}</Typography>
                    <Typography variant="h4">{reserva?.habitacio?.nom}</Typography>
                    <ReactDatePicker
                        onChange={onChange}
                        startDate={dateS}
                        endDate={dateF}
                        excludeDates={exclude}
                        selectsRange
                        selectsDisabledDaysInRange
                        locale={ca}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        inline
                    />
                    <Typography variant="caption" textAlign={"center"}>
                        L'estança no pot començar un dilluns
                    </Typography>
                    <Box mt={3} />
                    <Typography variant="h3">Dates</Typography>
                    <Box display={"flex"} justifyContent={"space-between"} mt={2}>
                        {dateS ? <Typography px={2}>{moment(dateS).format("DD/MM/YYYY")}</Typography> : ""}
                        <Box style={{ marginTop: 13, borderTop: "1px solid #b19975", width: "100%" }} />
                        {dateF ? <Typography px={2}>{moment(dateF).format("DD/MM/YYYY")}</Typography> : ""}
                    </Box>
                    <Box my={3}>
                        <Divider />
                    </Box>
                    <Typography variant="h3">Preu</Typography>
                    <Box display={"flex"} justifyContent={"center"} mt={2}>
                        <Box px={2}>
                            {preu ? <Typography>{preu} €</Typography> : "-"}
                            <Typography fontSize={11}>Preu nou</Typography>
                        </Box>
                        <Box px={2}>
                            <Typography>{reserva?.preu} €</Typography>
                            <Typography fontSize={11}>Preu antic</Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {!loading ? (
                    <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                        <Button onClick={() => setOpen(false)}>Tancar</Button>
                        <Button onClick={guardar} variant="contained">
                            Guardar
                        </Button>
                    </Box>
                ) : (
                    <Box display={"flex"} justifyContent={"center"} width={"100%"} mb={2}>
                        <CircularProgress size={24} />
                    </Box>
                )}
            </DialogActions>
        </Dialog>
    );
};
