import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    icon: { padding: 5 },
    badge: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 10,
        paddingLeft: 10,
        borderRadius: 15,
        color: "white",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    espera: {
        backgroundColor: "#ababab",
    },
    completada: {
        backgroundColor: "#8bc34a",
    },
    cancelada: {
        backgroundColor: "#ff1744",
    },
    pagada: {
        backgroundColor: "#03a9f4",
    },
}));

const ReservaState = (props) => {
    const classes = useStyles();
    const { state } = props;
    const style =
        state === "Cancel·lat"
            ? classes.cancelada
            : state === "Completat"
            ? classes.completada
            : state === "Pendent de pagament" || state === "Pendent de pagament - Enviat"
            ? classes.espera
            : classes.pagada;
    return (
        <Box className={clsx(classes.badge, style)}>
            <Typography style={{ color: "white", fontSize: 12 }}>{state}</Typography>
        </Box>
    );
};

export default ReservaState;
